import React, { useEffect } from "react";
import { useRouter } from "next/router";
import { withSSRContext } from "aws-amplify";
import { parseCookies } from "nookies";
import Spinner from "components/Spinner/Spinner";
import { getServerSideAuth } from "../apiService/authService";
import { destroyCognitoCookies } from "helpers/index";

import { useApolloClient } from "@apollo/client";
import Button from "components/Button/Button";

const { Auth } = withSSRContext();

export default function Home({ isAuth }) {
  const router = useRouter();
  const cookies = parseCookies();
  const client = useApolloClient();

  useEffect(() => {
    Auth.currentAuthenticatedUser({
      bypassCache: false
    })
      .then(user => {
        router.push("/valuation/list");
      })
      .catch(err => {
        console.log(err);
        destroyCognitoCookies(cookies);
        Auth.signOut();
        // location.reload();
      });
  }, [cookies]);

  return (
    <Spinner isLoad={true} />
  );
}
export const getServerSideProps = async context => {
  const isAuth = await getServerSideAuth(context);
  if (isAuth) {
    return {
      redirect: {
        destination: "/valuation/list",
        permanent: false
      }
    };
  }

  return {
    props: { isAuth }
  };
};
